<template>
  <ItemListComponent v-if="type == 'list'" :data="data" :type="type" :shrink="shrink" />
  <ItemSectionComponent v-if="type == 'section'" :data="data" :type="type" />
  <ItemSwiperComponent v-if="type == 'swiper'" :data="data" :type="type" :shrink="shrink" />
  <ItemImagesComponent v-on:click="showPopupFn" v-if="type == 'images'" :data="data" :type="type" :shrink="shrink"
    :colors="colors" :config="config" />
  <ItemOnlyTextComponent v-if="type == 'onlyText'" :data="data" :type="type" :shrink="shrink" />
  <ItemPricingComponent v-if="type == 'pricing'" :data="data" :type="type" :shrink="shrink" />
  <ItemImageTapeComponent v-if="type == 'imagestape'" :data="data" :type="type" :config="config" :colors="colors" />
  <HtmlComponent v-if="type == 'html'" :data="data" />
</template>
<script>
import ItemImagesComponent from './ItemImagesComponent.vue';
import ItemListComponent from './ItemListComponent.vue';
import ItemOnlyTextComponent from './ItemOnlyTextComponent.vue';
import ItemPricingComponent from './ItemPricingComponent.vue';
import ItemSectionComponent from './ItemSectionComponent.vue';
import ItemSwiperComponent from './ItemSwiperComponent.vue';
import ItemImageTapeComponent from './ItemImageTapeComponent.vue';
import HtmlComponent from './HtmlComponent.vue';


export default {
  name: 'ItemComponent',
  props: {
    data: Object,
    type: String,
    colors: Object,
    config: Object,
  },
  methods: {
    shrink(description, type) {
      if (description == null) {
        return '';
      }
      if (type == 'onlyText') {
        return description.slice(0, 150);
      }
      return description.slice(0, 45);
    },
    showPopupFn() {
      // console.log(data.target)
      // console.log(this.data)
      this.$emit('item-clicked', this.data);
    }
  },
  components: {
    ItemSectionComponent,
    ItemSwiperComponent,
    ItemImagesComponent,
    ItemOnlyTextComponent,
    ItemListComponent,
    ItemPricingComponent,
    ItemImageTapeComponent,
    HtmlComponent,
  }
}
</script>

<style scoped></style>
