<template>
    <div v-html="data.html">
    </div>
</template>
<script>
export default {
    name: "HtmlComponent",
    props: {
        data: Object,
    }
}
</script>