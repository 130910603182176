<template>
    <img v-if="isImage" :src="getSrc" :alt="alt">
    <video v-else autoplay loop muted>
        <source :src="getSrc" type="video/webm">
        Tu navegador no soporta el formato de video.
    </video>
</template>
<script>

const websiteRef = process.env.VUE_APP_WEBSITE_REF;

export default {
    name: 'LoadImageComponent',
    props: {
        src: String,
        alt: String,
    },
    data() {
        return {
            getSrc: null,
            isImage: true,
        };
    },
    async created() {
        try {
            const image = await import(`@/assets/${websiteRef}/images/${this.src}`);
            this.getSrc = image.default;
            if (this.getSrc.includes('.webm')) {
                this.isImage = false
            }
        } catch (error) {
            console.log(error)
            this.getSrc = this.src;
        }
    },
};
</script>